
const productsbaby = [
    { id: 1, name: 'Pañales', price: '1500' },
    { id: 2, name: 'Carrito', price: '2949' },
    { id: 3, name: 'Biberon', price: '50000' },
    { id: 1, name: 'Ropa', price: '1500' },
    { id: 2, name: 'Leche en polvo recetada', price: '2949' },
    { id: 3, name: 'Chupeta', price: '50000' },
  ];
  
  export default productsbaby;
  
