import React from 'react';
import './footerstyle.css'; // Importa el archivo de estilos CSS

const Footer = () => {
  return (
    <footer className="footer">
      <p>TCU UTN Educación Financiera 408 San Carlos</p>
    </footer>
  );
};

export default Footer;