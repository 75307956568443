import React from 'react';
import './homestyle.css'; // Importar el archivo CSS externo

const Home = () => {
  return (
    <div class="main-home">
        <div className='home-title'>
        <h3>Bienvenidos a la pagina web oficial del TCU 408 UTN San Carlos</h3>
        <h3>Educacion Financiera</h3>
        </div>
    </div>
  );
};

export default Home;
