import React from 'react';
import './spotifystyle.css';

const Spotify = () => {
  return (
    <div className='spotify'>
      <iframe 
        title="Unique Title Here"
        style={{ borderRadius: '12px' }} // Use an object for style, not a string
        src="https://open.spotify.com/embed/show/6grXCR9BoUiYxWJMFe0KMO?utm_source=generator" 
        width="100%" 
        height="352" 
        allowFullScreen={true} // Use camelCase for React props
        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" 
      ></iframe>
    </div>
  );
};

export default Spotify;
