import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';
import './navbarstyle.css';

const Navigation = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <header>
      <nav className={`navbar ${isOpen ? 'active' : ''}`}>
        <div className='logo'>
          <img src="https://www.utn.ac.cr/sites/default/files/images/documentosTCU.png" alt="UTN Logo" style={{ height: '70px', width: '70px' }} />
        </div>
        <div className='menu-icon' onClick={toggleMenu}>
          <FontAwesomeIcon icon={isOpen ? faTimes : faBars} />
        </div>
        <ul className={isOpen ? 'active' : ''}>
          <li>
            <Link to="/home">Inicio</Link>
          </li>
          <li>
            <Link to="/baby-table">Costo Recién Nacido</Link>
          </li>
          <li>
            <Link to="/cars-table">Costo Vehículos</Link>
          </li>
          <li>
            <Link to="/podcast">Podcast</Link>
          </li>
        </ul>
      </nav>
    </header>
  );
};

export default Navigation;
