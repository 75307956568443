import React from 'react';
import { Container, Table } from 'react-bootstrap';
import products from './babymodel';
import './babystyle.css';

const BabyTable = () => {
  return (
    <div className='baby-class'>
    <div className="baby-content">
      <Container className="table-baby">
        <h2 className="table-title">Costos de manutencion de un bebe en Costa Rica en un año</h2> {/* Título de la tabla */}
        <div className="table-wrapper">
          <Table striped bordered responsive>
            <thead>
              <tr>
                <th className='pr1'>Producto</th>
                <th className='pr2'>Precio</th>
              </tr>
            </thead>
            <tbody>
              {products.map((product) => (
                <tr key={product.id}>
                  <td>{product.name}</td>
                  <td>{product.price}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </Container>
    </div>
    <div className="blue-overlay"></div>
  </div>
  );
};


export default BabyTable;
